import imageHexa from "./images/hexagon.png";
export const config = {
  background: {
    image: "linear-gradient(45deg, #010207 0%, #093A5A 50%,  #00040F 100%)",
  },
  fpsLimit: 120,
  particles: {
    number: {
      value: 340,
      density: {
        enable: false,
        value_area: 450,
      },
    },
    color: {
      value: "#ffffff",
    },
    shape: {
      type: "polygon",
      stroke: {
        width: 0,
        color: "#000000",
      },
      polygon: {
        nb_sides: 6,
      },
      image: {
        src: imageHexa,
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 11.995201919232311,
      random: false,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: "#ffffff",
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 3,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: "repulse",
      },
      onclick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 145.7926666288686,
        line_linked: {
          opacity: 0,
        },
      },
      bubble: {
        distance: 400,
        size: 10,
        duration: 0.80995925904927,
        opacity: 0.7208637405538504,
        speed: 3,
      },
      repulse: {
        distance: 75,
        duration: 0.7,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};
